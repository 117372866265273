export default [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../pages/Dashboard.vue'),
        meta: {
            title: 'Dashboard',
            auth: true,
        },
    },
];
