export const horizontalGraphSeries = (state) => state.horizontalGraphSeries;
export const horizontalGraphLoading = (state) => state.horizontalGraphLoading;

export const performanceGraphSeries = (state) => state.performanceGraphSeries;
export const performanceGraphLoading = (state) => state.performanceGraphLoading;

export const selectedCheck = (state) => state.selectedCheck;
export const checksDetails = (state) => state.checksDetails;
export const checksDetailsLoading = (state) => state.checksDetailsLoading;
export const checksDetailsPagination = (state) => state.checksDetailsPagination;
export const checksDetailsMeta = (state) => state.checksDetailsMeta;
export const checksDetailsDraw = (state) => state.checksDetailsDraw;

export const verticalGraphSeries = (state) => state.verticalGraphSeries;
export const verticalGraphLoading = (state) => state.verticalGraphLoading;
