<template>
    <v-dialog
        v-if="showMenu"
        v-model="menu"
        :close-on-content-click="false"
        :disabled="selection === null || selection.length === 0"
        max-width="400"
        scrollable
    >
        <template v-slot:activator="{ on }">
            <v-btn
                text
                :loading="selection.length === 0"
                :disabled="selection.length === 0"
                v-on="on"
            >
                {{ name }} <small v-if="user.is_admin">({{ tenant.company.id }})</small>
            </v-btn>
        </template>

        <v-card>
            <v-card-text class="pt-2">
                <v-text-field
                    v-model="search"
                    placeholder="Search ..."
                    hide-details
                    autofocus
                    clearable
                    @keydown.down.native="focusNextOption"
                    @keydown.up.native="focusPreviousOption"
                    @keydown.enter.native="selectOption"
                />
            </v-card-text>

            <v-divider />

            <v-list ref="listbox" class="listbox">
                <v-list-item
                    v-for="(company, index) in companies"
                    :key="company.id"
                    :class="{ 'v-list-item--active' : focusedOptionIndex === index}"
                    @click="selectTenant(company.id)"
                >
                    <v-list-item-content>
                        <v-list-item-title
                            class="font-weight-medium"
                            :class="{'primary--text' : tenant.company && company.id === tenant.company.id}"
                        >
                            {{ company.name }}
                            <small v-if="user.is_admin">({{ company.id }})</small>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { isEmpty as _isEmpty } from 'lodash';

    export default {
        data: () => ({
            search: '',
            menu: false,
            focusedOptionIndex: null,
        }),

        computed: {
            ...mapGetters({
                user: 'app/user',
                tenant: 'app/tenant',
                selection: 'app/selection',
                meStatus: 'app/meStatus',
            }),

            name() {
                if (this.tenant.company) {
                    return this.tenant.company.name;
                }

                return 'Select company';
            },

            companies() {
                return this.selection.filter((company) => {
                    if (! this.search) {
                        return true;
                    }

                    return company.name.toLowerCase().includes(this.search.toLowerCase())
                        || company.id.toString().includes(this.search.toLowerCase());
                });
            },

            showMenu() {
                if (this.user.is_admin) {
                    return true;
                }

                if (this.user.permissions.includes('company view any')) {
                    return true;
                }

                if (! this.user.has_companies) {
                    return false;
                }

                if (this.meStatus !== 'success') {
                    return false;
                }

                if (this.selection.length === 1) {
                    return false;
                }

                return true;
            },
        },

        created() {
            this.loadTenantSelection();
        },

        methods: {
            selectTenant(id) {
                this.axios.post('/tenant', { id })
                    .then(() => {
                        this.menu = false;

                        // clear query sting to avoid incorrect filtering of data after switching
                        if (! _isEmpty(this.$route.query)) {
                            this.$router.replace({ query: null });
                        }

                        // Force reload of page after switching the tenant. Prevents loading issues with wrong data.
                        window.location.reload();
                    });
            },

            focusNextOption() {
                if (this.focusedOptionIndex === null) {
                    this.focusedOptionIndex = 0;

                    return;
                }

                if (this.focusedOptionIndex + 1 >= this.companies.length) {
                    return;
                }

                this.focusedOptionIndex += 1;

                this.$refs.listbox.$children[this.focusedOptionIndex].$el.scrollIntoView({
                    block: 'center',
                });
            },

            focusPreviousOption() {
                if (this.focusedOptionIndex === null) {
                    this.focusedOptionIndex = Object.keys(this.options).length - 1;

                    return;
                }

                if (this.focusedOptionIndex <= 0) {
                    return;
                }

                this.focusedOptionIndex -= 1;

                this.$refs.listbox.$children[this.focusedOptionIndex].$el.scrollIntoView({
                    block: 'center',
                });
            },

            selectOption() {
                if (this.focusedOptionIndex === null) {
                    return;
                }

                const companyId = this.companies[this.focusedOptionIndex].id;

                this.selectTenant(companyId);
            },

            ...mapActions({
                loadTenantSelection: 'app/loadTenantSelection',
            }),
        },
    };
</script>

<style scoped>
    .listbox {
        backface-visibility: hidden;
        flex: 1 1 auto;
        overflow-y: auto;
        max-height: 80vh;
    }
</style>
