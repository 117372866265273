export default [
    {
        path: '/campaign-manager/',
        component: () => import('../pages/Overview.vue'),
        name: 'campaign-manager-overview',
        meta: {
            auth: true,
            navigation: 'campaignManager',
            title: 'Campaign Overview',
            permissions: ['campaign-manger show'],
        },
    },
];
