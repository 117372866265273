import { merge as _merge } from 'lodash';
import projects from './project';
import filters from './filters';
import features from './features';
import permissions from './permissions';
import helpers from './helpers';

const mixins = {};
_merge(mixins, projects);
_merge(mixins, filters);
_merge(mixins, features);
_merge(mixins, permissions);
_merge(mixins, helpers);

export default mixins;
