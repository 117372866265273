/* eslint no-param-reassign: 0 */

import { pickBy as _pickBy, identity as _identity } from 'lodash';
import defaults from './defaults';

export const tree = (state, payload) => {
    state.tree = payload;
};

export const treeMeta = (state, payload) => {
    state.treeMeta = payload;
};

export const treeLoading = (state, payload) => {
    state.treeLoading = payload;
};

export const treeSelection = (state, payload) => {
    state.treeSelection = payload;
};

export const activePanels = (state, payload) => {
    state.activePanels = payload;
};

export const updateKeywordIds = (state, payload) => {
    state.keywordIds = payload;
};

export const updateMindsetCount = (state, payload) => {
    state.mindsetCount = payload;
};

export const searchVolume = (state, payload) => {
    state.searchVolume = payload;
};

export const searchVolumeLoading = (state, payload) => {
    state.searchVolumeLoading = payload;
};

export const removeSelectedNode = (state, payload) => {
    state.selectedNodes = state.selectedNodes.filter((e) => e !== payload);
};

export const clearSelectedNodes = (state) => {
    state.selectedNodes = [];
};

export const toggleFilterVisibility = (state) => {
    state.filterVisibility = ! state.filterVisibility;
};

export const updateFilterLayer = (state, payload) => {
    state.filterLayer = payload;
};

export const updateFilter = (state, payload) => {
    const data = {
        ...state.filter,
        ...payload,
    };

    // Get rid of null values in filter
    state.filter = _pickBy(data, _identity);
};

export const updateFilterErrors = (state, payload) => {
    state.filterErrors = payload;
};

export const clearFilter = (state) => {
    const defaultsLocal = defaults();

    state.filterLayer = defaultsLocal.filterLayer;
    state.filter = defaultsLocal.filter;
};

export const increaseCompetitorDraw = (state) => {
    state.competitorsDraw += 1;
};

export const mergeOptions = (state, payload) => {
    state.options = {
        ...state.options,
        ...payload,
    };
};
