/* eslint no-param-reassign: 0 */

import defaults from '../../vuex/defaults';

export const project = (state, payload) => {
    state.project = payload;
};

export const projectDraw = (state) => {
    state.projectDraw += 1;
};

export const projectLoading = (state, payload) => {
    state.projectLoading = payload;
};

export const keywords = (state, payload) => {
    state.keywords = payload;
};

export const keywordCount = (state, payload) => {
    state.keywordCount = payload;
};

export const competitorDataDraw = (state) => {
    state.competitorDataDraw += 1;
};

export const keywordsLoading = (state) => {
    state.keywordsLoading = ! state.keywordsLoading;
};

export const rankingDataRunningChecks = (state, payload) => {
    state.rankingDataRunningChecks = payload;
};

export const rankingDataGraphSeries = (state, payload) => {
    state.rankingDataGraphSeries = payload;
};

export const rankingDataPerformance = (state, payload) => {
    state.rankingDataPerformance = payload;
};

export const rankingDataKeywordsPositioning = (state, payload) => {
    state.rankingDataKeywordsPositioning = payload;
};

export const rankingDataKeywordsPositioningLoading = (state) => {
    state.rankingDataKeywordsPositioningLoading = ! state.rankingDataKeywordsPositioningLoading;
};

export const projectsLoading = (state, payload) => {
    state.projectsLoading = payload;
};

export const projectsDraw = (state) => {
    state.projectsDraw += 1;
};

export const projectsDrawReset = (state) => {
    state.projectsDraw = 0;
};

export const projectsMeta = (state, payload) => {
    state.projectsMeta = payload;
};

export const projectsAllIds = (state, payload) => {
    state.projectsAllIds = payload;
};

export const projectsSelected = (state, payload) => {
    state.projectsSelected = payload;
};

export const projectsSelectAll = (state) => {
    state.projectsSelected = state.projectsAllIds.map((projectId) => ({ id: projectId }));
};

export const projects = (state, payload) => {
    state.projects = payload;
};

export const clearProjects = (state) => {
    state.projects = [];
};

export const seaSections = (state, payload) => {
    state.seaSections = payload;
};

export const changeSeaSectionActive = (state, payload) => {
    state.seaSections = state.seaSections.map((section) => {
        section.mindsets = section.mindsets.map((mindset) => {
            if (mindset.id === payload.sectionId) {
                mindset.active = payload.active;
            }
            return mindset;
        });
        return section;
    });
};

export const seaSectionsLoading = (state) => {
    state.seaSectionsLoading = ! state.seaSectionsLoading;
};

export const increaseContentScoreListRenderKey = (state) => {
    state.contentScoreListRenderKey += 1;
};

export const resetState = (state) => {
    Object.assign(state, defaults());
};
