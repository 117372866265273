export default [
    {
        path: '/branchenanalyse',
        name: 'industry-analysis',
        component: () => import('../pages/LandingPage.vue'),
        meta: {
            auth: false,
            title: 'Branchenanalyse',
        },
    },
];
