export const project = (state) => state.project;

export const projectDraw = (state) => state.projectDraw;
export const projectLoading = (state) => state.projectLoading;

export const keywordsLoading = (state) => state.keywordsLoading;
export const keywords = (state) => state.keywords;
export const keywordCount = (state) => state.keywordCount;

export const competitorDataDraw = (state) => state.competitorDataDraw;

export const rankingDataRunningChecks = (state) => state.rankingDataRunningChecks;
export const rankingDataGraphSeries = (state) => state.rankingDataGraphSeries;
export const rankingDataPerformance = (state) => state.rankingDataPerformance;
export const rankingDataKeywordsPositioning = (state) => state.rankingDataKeywordsPositioning;
export const rankingDataKeywordsPositioningLoading = (state) => state.rankingDataKeywordsPositioningLoading;

export const projects = (state) => state.projects;
export const projectsMeta = (state) => state.projectsMeta;
export const projectsAllIds = (state) => state.projectsAllIds;
export const projectsSelected = (state) => state.projectsSelected;
export const projectsDraw = (state) => state.projectsDraw;
export const projectsLoading = (state) => state.projectsLoading;

export const contentScoreListRenderKey = (state) => state.contentScoreListRenderKey;

export const seaSectionsLoading = (state) => state.seaSectionsLoading;
export const seaSections = (state) => state.seaSections;
