<template>
    <div dusk="navigation">
        <v-navigation-drawer
            v-model="drawer"
            fixed
            app
            mobile-breakpoint="1650"
        >
            <div class="nav-wrap d-flex flex-column">
                <v-list dense>
                    <template v-for="(item, key) in navigation">
                        <v-list-item
                            v-if="item.href"
                            :key="key"
                            :href="item.href"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item
                            v-if="!item.children && item.route && !item.href"
                            :key="key"
                            :to="{ name: item.route }"
                        >
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-group
                            v-if="item.children && !item.route && !item.href"
                            :key="key"
                            :prepend-icon="item.icon"
                            no-action
                        >
                            <template v-slot:activator>
                                <v-list-item-title dusk="navigation-passports">
                                    {{ item.title }}
                                </v-list-item-title>
                            </template>

                            <v-list-item
                                v-for="child in item.children"
                                :key="child.route"
                                :to="{ name: child.route }"
                                :disabled="hasNoSelection"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ child.title }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                    </template>

                    <template v-if="user.permissions.includes('management show') || user.is_admin">
                        <v-subheader>
                            Management
                        </v-subheader>
                        <v-list-item
                            v-for="item in managementNavigation"
                            :key="item.route"
                            :to="{ name: item.route }"
                        >
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                    <template v-if="user.is_admin">
                        <v-subheader>
                            Administration
                        </v-subheader>
                        <v-list-item
                            v-for="item in adminNavigation"
                            :key="item.route"
                            :to="{ name: item.route }"
                        >
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list>

                <div class="footer pa-4">
                    <img src="/storage/images/tuev_logo.png" alt="">

                    <ul class="footer-navigation text-caption">
                        <li>
                            <a href="/imprint">Imprint</a>
                        </li>
                        <li>
                            <a href="/terms-of-service">Terms of Service</a>
                        </li>
                        <li>
                            <a href="/privacy-policy">Privacy Policy</a>
                        </li>
                    </ul>
                </div>
            </div>
        </v-navigation-drawer>

        <v-app-bar
            v-if="appVariant !== 'public-analysis'"
            color="blue-gray"
            fixed
            app
        >
            <v-app-bar-nav-icon @click="drawer = !drawer" />
            <v-toolbar-title>
                <router-link :to="{ name: 'dashboard' }">
                    {{ name }}
                </router-link>
            </v-toolbar-title>
            <v-spacer />

            <v-toolbar-items v-if="appVariant === 'enterprise'">
                <notifications />
            </v-toolbar-items>

            <v-toolbar-items v-if="appVariant === 'enterprise'">
                <whats-new />
            </v-toolbar-items>

            <v-toolbar-items v-if="appVariant === 'enterprise'" class="hidden-sm-and-down">
                <company-menu />
            </v-toolbar-items>

            <v-toolbar-items class="hidden-sm-and-down" dusk="user-dropdown">
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            text
                            v-on="on"
                        >
                            {{ user.name }}
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item :to="{ name: 'account' }">
                            <v-list-item-title>
                                Account
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="appVariant === 'content-score' && user.is_customer"
                            href="/billing"
                        >
                            <v-list-item-title>Billing</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click.prevent="logout">
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="user.impersonate" @click.prevent="unimpersonate">
                            <v-list-item-title>Unimpersonate</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar-items>
        </v-app-bar>
    </div>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex';
    import CompanyMenu from './navigation/CompanyMenu.vue';
    import Notifications from './navigation/Notifications.vue';
    import WhatsNew from './navigation/WhatsNew.vue';

    export default {
        components: {
            CompanyMenu,
            Notifications,
            WhatsNew,
        },

        data: () => ({
            drawer: null,

            managementNavigation: [
                {
                    route: 'admin/status',
                    icon: 'dataset',
                    title: 'Checks',
                },
            ],

            adminNavigation: [
                {
                    route: 'companies',
                    icon: 'business',
                    title: 'Companies',
                },
                {
                    route: 'data-management',
                    icon: 'view_headline',
                    title: 'Data Management',
                },
                {
                    route: 'assistance',
                    icon: 'info',
                    title: 'Assistance Management',
                },
                {
                    route: 'admin/data-collection',
                    icon: 'library_books',
                    title: 'Data Collection',
                },
                {
                    route: 'users',
                    icon: 'people',
                    title: 'Users',
                },

            ],
        }),

        computed: {
            name() {
                if (this.tenant.company) {
                    return this.tenant.company.name;
                }

                return this.appName;
            },

            hasNoSelection() {
                return this.tenant.company === null;
            },

            ...mapGetters({
                user: 'app/user',
                tenant: 'app/tenant',
                navigation: 'app/navigation',
                appVariant: 'app/variant',
                appName: 'app/name',
            }),
        },

        watch: {
            name(value) {
                document.title = `${value} | ${window.config.appName}`;
            },
        },

        methods: {
            logout() {
                this.$store.commit('app/resetState');
                this.axios.post('/auth/logout').then(() => {
                    this.resetState();
                    window.location.replace('/');
                });
            },

            unimpersonate() {
                this.axios.post('auth/unimpersonate')
                    .then(() => {
                        this.resetState();
                        // Force reload of page. Prevents loading issues with wrong data.
                        window.location.replace('/');
                    });
            },

            ...mapMutations({
                resetStateApp: 'app/resetState',
                resetStateProjects: 'projects/resetState',
            }),

            ...mapActions({
                loadUser: 'app/loadUser',
            }),

            resetState() {
                this.resetStateApp();
                this.resetStateProjects();
            },
        },
    };
</script>

<style scoped>
    .v-navigation-drawer {
        z-index: 21;
    }

    .v-toolbar {
        z-index: 20;
    }

    /* Remove link styling from name */
    .v-toolbar__title a {
        color: rgba(0, 0, 0, .87);
        text-decoration: none;
    }

    .nav-wrap {
        height: 100vh;
    }

    .footer {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer img {
        width: 75px;
    }

    .footer-navigation {
        margin: 0;
        padding: .5rem 0 0 0;
        display: flex;
        gap: .2rem .5rem;
        flex-wrap: wrap;
        list-style: none;
        justify-content: center;
    }
</style>
