import Vue from 'vue';

/* eslint import/prefer-default-export: 0 */
export const loadAssistance = ({ commit }) => {
    commit('assistanceLoading', true);

    Vue.axios.get('assistance')
        .then((response) => {
            commit('assistance', response.data.data);
            commit('assistanceLoading', false);
        });
};
