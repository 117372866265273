import admin from '../app/admin/routes';
import auth from '../app/auth/routes';
import account from '../app/account/routes';
import campaignManager from '../app/campaign-manager/routes';
import dashboard from '../app/dashboard/routes';
import keywordTree from '../app/keyword-tree/routes';
import landingPageIndustry from '../app/landing-page-industry/routes';
import landingPagePlenty from '../app/landing-page-plenty/routes';
import odbDashboard from '../app/obd-dashboard/routes';
import passports from '../app/passports/routes';
import passportCorrelation from '../app/passport-correlation/routes';
import shopIntegration from '../app/shop-integration/routes';
import embeddable from '../app/embeddable/routes';
import dataManagement from '../app/data-management/routes';
import assistance from '../app/assistance/routes';
import contentScoreStandAlone from '../app/content-score-stand-alone/routes';

export default [
    ...admin,
    ...account,
    ...assistance,
    ...auth,
    ...campaignManager,
    ...dashboard,
    ...dataManagement,
    ...embeddable,
    ...keywordTree,
    ...landingPageIndustry,
    ...landingPagePlenty,
    ...odbDashboard,
    ...passportCorrelation,
    ...passports,
    ...shopIntegration,
    ...contentScoreStandAlone,

    {
        path: '/404',
        component: () => import('../components/404.vue'),
        name: '404',
        meta: {
            auth: true,
        },
    },

    {
        path: '/403',
        component: () => import('../components/403.vue'),
        name: '403',
        meta: {
            auth: true,
        },
    },
];
