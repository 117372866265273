export default function defaults() {
    return {
        tree: [],
        treeMeta: {
            selectedNodes: [],
        },
        treeLoading: true,
        treeSelection: [],
        activePanels: [],
        keywordIds: [],
        mindsetCount: 0,
        searchVolume: '-',
        searchVolumeLoading: false,

        filterVisibility: false,
        filterLayer: '',
        filter: {
            keywords: 'relevant',
        },
        filterErrors: {},

        competitorsDraw: 0,

        options: {
            deleteKeywords: true,
            detachKeywords: false,
        },
    };
}
