export default [
    {
        path: '/passports/monitoring/:projectId',
        component: () => import('../pages/MonitoringPassportSingle.vue'),
        name: 'passport-monitoring',
        meta: {
            auth: true,
            permissions: ['passport-monitoring show'],
            navigationParent: 'passports',
            feature: 'monitoring_passports',
        },
    },
    {
        path: '/passports/social-media/:projectId',
        component: () => import('../pages/SocialMediaPassportSingle.vue'),
        name: 'passport-social-media',
        meta: {
            auth: true,
            permissions: ['passport-social-media show'],
            navigationParent: 'passports',
            feature: 'social_media_passports',
        },
    },
    {
        path: '/passports/content/:projectId',
        component: () => import('../pages/ContentPassportSingle.vue'),
        name: 'passport-content',
        meta: {
            auth: true,
            permissions: ['passport-content show'],
            navigationParent: 'passports',
            feature: 'content_passports',
        },
    },
    {
        path: '/passports/sea/:projectId',
        component: () => import('../pages/SeaPassportSingle.vue'),
        name: 'passport-sea',
        meta: {
            auth: true,
            permissions: ['passport-sea show'],
            navigationParent: 'passports',
            feature: 'sea_media_passports',
        },
    },
    {
        path: '/market-analysis/content-passports',
        component: () => import('../pages/ContentPassportList.vue'),
        name: 'market-analysis/content-passports',
        meta: {
            auth: true,
            navigation: 'passports',
            title: 'Content Passports',
            permissions: ['passport-content show'],
            feature: 'content_passports',
        },
    },
    {
        path: '/market-analysis/monitoring-passports',
        component: () => import('../pages/MonitoringPassportList.vue'),
        name: 'market-analysis/monitoring-passports',
        meta: {
            auth: true,
            navigation: 'passports',
            title: 'Monitoring Passports',
            permissions: ['passport-monitoring show'],
            feature: 'monitoring_passports',
        },
    },
    {
        path: '/market-analysis/social-media-passports',
        component: () => import('../pages/SocialMediaPassportList.vue'),
        name: 'market-analysis/social-media-passports',
        meta: {
            auth: true,
            navigation: 'passports',
            title: 'Social Media Passports',
            permissions: ['passport-social-media show'],
            feature: 'social_media_passports',
        },
    },
    {
        path: '/market-analysis/sea-passports',
        component: () => import('../pages/SeaPassportList.vue'),
        name: 'market-analysis/sea-passports',
        meta: {
            auth: true,
            navigation: 'passports',
            title: 'SEA Passports',
            permissions: ['passport-sea show'],
            feature: 'sea_passports',
        },
    },
    {
        path: '/market-analysis/seasonality-calendar/:projectType',
        name: 'market-analysis/seasonality-calendar',
        component: () => import('../pages/SeasonalityCalendar.vue'),
        meta: {
            auth: true,
            permissions: ['passport-content show'],
            navigationParent: 'passports',
        },
    },
    {
        path: '/market-analysis/sea-budget-calendar',
        name: 'market-analysis/sea-budget-calendar',
        component: () => import('../pages/BudgetCalendar.vue'),
        meta: {
            auth: true,
            permissions: ['passport-sea show'],
            navigationParent: 'passports',
        },
    },
];
