<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on }">
            <v-btn
                text
                v-on="on"
            >
                <v-badge
                    :value="notifications.counter"
                    right
                    color="warning"
                >
                    <template v-slot:badge>
                        <span>{{ notifications.counter }}</span>
                    </template>
                    <v-icon>
                        notifications
                    </v-icon>
                </v-badge>
            </v-btn>
        </template>
        <v-list v-if="notifications.items.length > 0">
            <v-list-item
                v-for="(item, key) in notifications.items"
                :key="key"
            >
                <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
        </v-list>

        <v-list v-else>
            <v-list-item>
                <v-list-item-title>No notifications.</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        data() {
            return {
                interval: null,
                notifications: {
                    counter: null,
                    items: [],
                },
            };
        },

        computed: {
            ...mapGetters({
                tenant: 'app/tenant',
            }),
        },

        watch: {
            tenant(tenant) {
                if (tenant.company) {
                    this.init();
                }
            },
        },

        mounted() {
            this.init();
        },

        destroyed() {
            clearInterval(this.interval);
        },

        methods: {
            init() {
                // No tenant selected yet
                if (! this.tenant.company) {
                    return;
                }

                // First run
                this.poll();

                // in local env with don't need the notifications
                if (window.config.env === 'local') {
                    return;
                }

                // Set interval
                this.interval = setInterval(this.poll, 60000);
            },

            poll() {
                this.axios.get('notifications')
                    .then(({ data }) => {
                        this.notifications = data.data;
                    });
            },
        },
    };
</script>
