export default [
    {
        path: '/obd-general',
        name: 'obd-general',
        component: () => import('../pages/General.vue'),
        meta: {
            auth: true,
            title: 'Dashboard',
        },

    },
    {
        path: '/obd-visibility-index',
        name: 'obd-visibility-index',
        component: () => import('../../visibility-index/pages/VisibilityIndex.vue'),
        meta: {
            auth: true,
            title: 'Visibility Index',
            feature: 'visibility_index',
        },

    },
];
