<template>
    <v-snackbar
        :value="flash"
        :color="type"
    >
        {{ flashMessage }}

        <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    import { get } from 'lodash';

    export default {
        data() {
            return {
                flash: null,
                timeout: null,
            };
        },

        computed: {
            flashMessage() {
                return get(this.flash, 'message');
            },

            type() {
                return get(this.flash, 'type');
            },

            ...mapGetters({
                getFlash: 'app/flash',
            }),
        },

        watch: {
            getFlash(flash) {
                if (flash === null) {
                    return;
                }

                this.flash = this.getFlash;
                this.clearFlash();
            },
        },

        destroyed() {
            clearTimeout(this.timeout);
            this.flash = null;
        },

        methods: {
            ...mapMutations({
                clearFlash: 'app/clearFlash',
            }),
        },
    };
</script>
