export default [
    {
        path: '/market-analysis/analysis',
        component: () => import('../pages/Analysis.vue'),
        name: 'market-analysis/analysis',
        meta: {
            title: 'Market Analysis',
            auth: true,
        },
    },
];
