export default [
    {
        path: '/assistance',
        name: 'assistance',
        component: () => import('../pages/AssistanceManagement.vue'),
        meta: {
            title: 'Assistance Management',
            auth: true,
        },
    },
];
