/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from 'vue';
import Vuetify from 'vuetify';
import router from './router';
import store from './vuex';
import './http';
import mixins from './mixins';
import sentryInit from './sentry';
import '../sass/app.scss';

import App from './components/App.vue';

window.Vue = Vue;

Vue.use(Vuetify);
const vuetifyOpts = {
    icons: {
        iconfont: 'md',
    },
};

Vue.mixin(mixins);

sentryInit();

/* eslint no-unused-vars: 0 */
const app = new Vue({
    router,
    store,
    vuetify: new Vuetify(vuetifyOpts),
    el: '#app',
    render: (h) => h(App),
});
