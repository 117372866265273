<template>
    <footer class="text-sm-center pt-4">
        <div class="footer">
            <img src="/storage/images/tuev_logo.png" alt="">
        </div>
        <v-container>
            <ul>
                <li>
                    <a href="/imprint">Imprint</a>
                </li>
                <li>
                    <a href="/terms-of-service">Terms of Service</a>
                </li>
                <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                </li>
            </ul>
        </v-container>
    </footer>
</template>

<script>
    export default {

    };
</script>

<style scoped>
    ul {
        list-style: none;
        display: flex;
        justify-content: center;
        padding: 0;
    }

    ul > li {
        padding: 0 1rem;
    }

    .footer img {
        width: 80px;
    }
</style>
