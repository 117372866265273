/* eslint arrow-body-style: 0 */
/* eslint-disable consistent-return */
import Vue from 'vue';
import * as qs from 'qs';

// eslint-disable-next-line import/prefer-default-export
export const loadSearchVolume = ({ commit }, payload) => {
    if (payload.length === 0) {
        commit('searchVolume', '-');

        return;
    }

    commit('searchVolumeLoading', true);

    return Vue.axios.post('keyword-tree/search-volume', {
        keywordIds: payload,
    })
        .then(({ data }) => {
            commit('searchVolume', data.searchVolume);
            commit('searchVolumeLoading', false);
        })
        .catch(() => {
            commit('searchVolumeLoading', false);
        });
};

export const loadTree = ({ commit, state }, projectId = null) => { // eslint-disable-line import/prefer-default-export
    commit('treeLoading', true);
    commit('updateKeywordIds', []);

    const payload = {
        filterLayer: state.filterLayer.toLowerCase(),
        ...state.filter,
    };

    const payloadString = qs.stringify(payload, {
        arrayFormat: 'brackets',
        encode: false,
    });

    const projectIdString = projectId ? `/${projectId}` : '';

    return Vue.axios.get(`/keyword-tree${projectIdString}?${payloadString}`)
        .then(({ data }) => {
            commit('tree', data.data);
            commit('treeMeta', data.meta);
            commit('treeLoading', false);
        });
};
