import { Crisp } from 'crisp-sdk-web';

export default function load(user = null, tenant = null) {
    if (window.config.env === 'local' || window.config.env === 'testing') {
        return;
    }

    const websiteId = '8877a741-db53-4115-8fd3-8c4ba4cf1df3';
    Crisp.configure(websiteId, {});

    if (user) {
        Crisp.user.setNickname(user.name);
        Crisp.user.setEmail(user.email);
    }

    if (tenant) {
        Crisp.user.setCompany(tenant.company.name, {});
    }

    Crisp.session.setData({
        is_praidict_enterprise: true,
    });
}

export function openArticle(slug) {
    Crisp.chat.openHelpdeskArticle('de', slug);
}
