export default {
    methods: {
        $can(permissionName) {
            const user = this.$store.getters['app/user'];

            // All users without a role have access to all permissions
            if (user.roles.length === 0) {
                return true;
            }

            return user.permissions.indexOf(permissionName) !== -1;
        },

        $hasRole(roleName) {
            const user = this.$store.getters['app/user'];

            return user.roles.indexOf(roleName) !== -1;
        },
    },
};
