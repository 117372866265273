/* eslint no-param-reassign: 0 */

import defaults from './defaults';

export const updateClusterIds = (state, payload) => {
    state.clusterIds = payload;
};

export const resetState = (state) => {
    Object.assign(state, defaults());
};
