import Vue from 'vue';
import Vuex from 'vuex';
import app from '../app/vuex';
import projects from '../app/passports/vuex';
import passportCorrelation from '../app/passport-correlation/vuex';
import visibilityIndex from '../app/visibility-index/vuex';
import keywordTree from '../app/keyword-tree/vuex';
import shopIntegration from '../app/shop-integration/vuex';
import industryLandingPage from '../app/landing-page-industry/vuex';
import assistance from '../app/assistance/vuex';
import contentScoreStandAlone from '../app/content-score-stand-alone/vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app,
        projects,
        passportCorrelation,
        visibilityIndex,
        keywordTree,
        shopIntegration,
        industryLandingPage,
        assistance,
        contentScoreStandAlone,
    },
});
