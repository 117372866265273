export default [
    {
        path: '/passports/correlation/:projectId',
        component: () => import('../pages/CorrelationPassportSingle.vue'),
        name: 'passport-correlation',
        meta: {
            auth: true,
            navigationParent: 'marketAnalysis',
            feature: 'correlation_passports',
        },
    },
    {
        path: '/market-analysis/correlation-passports',
        component: () => import('../pages/CorrelationPassportList.vue'),
        name: 'market-analysis/correlation-passports',
        meta: {
            auth: true,
            navigation: 'passports',
            title: 'Correlation Passports',
            feature: 'correlation_passports',
        },
    },
];
