/* eslint no-param-reassign: 0 */

export const increaseParameteresDraw = (state) => {
    state.parametersDraw += 1;
};

export const increaseGraphDraw = (state) => {
    state.graphDraw += 1;
};

export const increaseTableDraw = (state) => {
    state.tableDraw += 1;
};

export const demandSeries = (state, payload) => {
    state.demandSeries = payload;
};
