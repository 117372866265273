export default [
    {
        path: '/contentscoring',
        component: () => import('../pages/ContentScoreStandAlone.vue'),
        name: 'content-scoring',
        meta: {
            title: 'Contentscoring',
            auth: true,
            permissions: ['content-score-stand-alone content-score'],
        },
    },

    {
        path: '/content-passports',
        component: () => import('../pages/ContentPassportList.vue'),
        name: 'content-passports-stand-alone',
        meta: {
            title: 'Content Projects',
            auth: true,
            permissions: ['content-score-stand-alone content-score'],
        },
    },

    {
        path: '/content-passports/:projectId',
        component: () => import('../pages/ContentPassportSingle.vue'),
        name: 'content-passports-stand-alone-single',
        meta: {
            auth: true,
            permissions: ['content-score-stand-alone content-score'],
        },
    },

    {
        path: '/contentscoring/success',
        component: () => import('../pages/SubscribeSuccess.vue'),
        name: 'content-scoring-success',
        meta: {
            auth: true,
            permissions: ['content-score-stand-alone content-score'],
        },
    },
    {
        path: '/contentscoring/cancel',
        component: () => import('../pages/SubscribeCancel.vue'),
        name: 'content-scoring-cancel',
        meta: {
            auth: true,
            permissions: ['content-score-stand-alone content-score'],
        },
    },
];
