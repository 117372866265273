<template>
    <v-btn elevation="0" @click="open">
        What's New
    </v-btn>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { openArticle } from '../../helpers/crisp';

    export default {
        computed: {
            ...mapGetters({
                assistance: 'assistance/assistance',
            }),
        },

        methods: {
            open() {
                const uuid = 'a607b877-c57f-4914-8613-656b3d6cbfa4';
                const selected = this.assistance.find((AssistanceItem) => AssistanceItem.uuid === uuid);

                if (! selected) {
                    return;
                }

                openArticle(selected.crisp_slug);
            },
        },
    };
</script>
