/* eslint arrow-body-style: 0 */

import Vue from 'vue';

export const loadHorizontalGraph = ({ commit }) => {
    commit('horizontalGraphLoading', true);
    const curves = ['company', 'competitors'];
    const curvesString = curves.join('-');

    Vue.axios.get(`/visibility-index/horizontal-graph/${curvesString}`)
        .then(({ data }) => {
            if (data && data.length > 0) {
                commit('horizontalGraphSeries', data);
            }

            commit('horizontalGraphLoading', false);
        })
        .catch(() => {
            commit('horizontalGraphLoading', false);
        });
};

export const loadPerformanceGraph = ({ commit, state }) => {
    commit('performanceGraphLoading', true);

    let url = '/visibility-index/performance';
    const check = state.selectedCheck;

    if (check) {
        url += `/${check}`;
    }

    return Vue.axios.get(url)
        .then(({ data }) => {
            if (data && data.length > 0) {
                commit('performanceGraphSeries', data);
            }

            commit('performanceGraphLoading', false);
        })
        .catch(() => {
            commit('performanceGraphLoading', false);
        });
};

export const loadChecksDetails = ({ commit, state }) => {
    const check = state.selectedCheck;
    const pagination = state.checksDetailsPagination;

    if (! check) {
        return;
    }

    commit('checksDetailsLoading', true);
    commit('checksDetailsDraw');

    Vue.axios.get(`/visibility-index/checks-details/${check}`, {
        params: {
            draw: state.checkDetailsDraw,
            ...pagination,
        },
    })
        .then(({ data }) => {
            commit('checksDetails', data.data);
            commit('checksDetailsMeta', data.meta);

            commit('checksDetailsLoading', false);
        });
};

export const loadVerticalGraph = ({ commit }) => {
    commit('verticalGraphLoading', true);
    Vue.axios.get('/visibility-index/vertical-graph')
        .then(({ data }) => {
            if (data) {
                commit('verticalGraphSeries', data);
            }

            commit('verticalGraphLoading', false);
        })
        .catch(() => {
            commit('verticalGraphLoading', false);
        });
};
