export default [
    {
        path: '/register',
        name: 'register',
        component: () => import('../pages/Register.vue'),
        meta: {
            auth: false,
            title: 'Register',
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../pages/Login.vue'),
        meta: {
            auth: false,
            title: 'Login',
        },
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../pages/ForgetPassword.vue'),
        meta: {
            auth: false,
            title: 'Forgot Password',
        },
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('../pages/ResetPassword.vue'),
        meta: {
            auth: false,
            title: 'Reset Password',
        },
    },
];
