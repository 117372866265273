export default [
    {
        path: '/account',
        component: () => import('../pages/Account.vue'),
        name: 'account',
        meta: {
            alwaysAllowed: true,
            auth: true,
        },
    },
];
