import Vue from 'vue';
import Router from 'vue-router';
import router from './router';
import beforeEach from './beforeEach';
import afterEach from './afterEach';

Vue.use(Router);

router.beforeEach(beforeEach);
router.afterEach(afterEach);

export default router;
