import { get as _get } from 'lodash';

export default {
    methods: {
        getFeature(name) {
            const tenant = this.$store.getters['app/tenant'];
            return _get(tenant, `features.feature:${name}`);
        },

        featureEnabled(name) {
            return this.getFeature(name) === 'enabled';
        },
    },
};
