export default [
    {
        path: '/shop-integration/plenty-import',
        component: () => import('../pages/PlentyImport.vue'),
        name: 'shop-integration-plenty-import',
        meta: {
            auth: false,
        },
    },

    {
        path: '/shop-integration/add-topics',
        component: () => import('../pages/AddTopics.vue'),
        name: 'shop-integration-add-topics',
        meta: {
            auth: true,
        },
    },
];
