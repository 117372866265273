/* eslint arrow-body-style: 0 */
/* eslint import/prefer-default-export: 0 */

import Vue from 'vue';

/* eslint import/prefer-default-export: 0 */
export const loadStatus = ({ commit }, uuid) => {
    if (! uuid) {
        commit('statusLoading', false);
        return;
    }

    Vue.axios.get(`/shop-integration/plenty-import/${uuid}/status`)
        .then(({ data }) => {
            commit('status', data);
            commit('statusLoading', false);
        });
};

export const loadStatusAddTopics = ({ commit }, uuid) => {
    if (! uuid) {
        commit('statusLoading', false);
        return;
    }

    commit('statusLoading', true);
    Vue.axios.get(`/shop-integration/add-topics/${uuid}/status`)
        .then(({ data }) => {
            commit('status', data);
            commit('statusLoading', false);
        });
};
