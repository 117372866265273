<template>
    <v-app-bar
        app
        class="home-toolbar"
    >
        <v-toolbar-title>
            <router-link to="/">
                {{ appName }}
            </router-link>
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
            <v-btn
                v-for="(route, key) in routes"
                :key="key"
                text
                :to="getPath(route)"
            >
                {{ getName(route) }}
            </v-btn>
        </v-toolbar-items>
    </v-app-bar>
</template>

<script>
    export default {
        data() {
            return {
                routes: [
                    'login',
                ],
            };
        },

        computed: {
            appName() {
                return window.config.appName;
            },
        },

        methods: {
            getPath(route) {
                return this.$router.resolve({ name: route }).href;
            },

            getName(route) {
                return this.$router.resolve({ name: route }).route.meta.title;
            },
        },
    };
</script>

<style>
    .home-toolbar .v-toolbar__content {
        max-width: 1785px;
        margin: 0 auto;
    }

    /* Remove link styling from name */
    .home-toolbar .v-toolbar__title a {
        color: rgba(0, 0, 0, .87);
        text-decoration: none;
    }
</style>
