export default function defaults() {
    return {
        auth: false,
        meLoaded: false,
        meStatus: null,

        name: null,
        variant: null,

        user: {
            is_admin: false,
            has_companies: false,
            roles: [],
            permissions: [],
        },

        tenant: {
            company: null,
        },

        selection: [],

        flash: null,

        navigation: [],

        typeColors: {
            company: 'blue darken-2',
            department: 'teal',
            project: 'brown',
            topic: 'lime',
            cluster: 'blue-grey',
            mindset: 'deep-orange darken-2',
            keyword: 'amber darken-1',
            expression: 'amber darken-1',
        },

        months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
    };
}
