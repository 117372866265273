export default [
    {
        path: '/admin/companies',
        component: () => import('../pages/Companies.vue'),
        name: 'companies',
        meta: {
            admin: true,
            auth: true,
        },
    },
    {
        path: '/admin/users',
        component: () => import('../pages/Users.vue'),
        name: 'users',
        meta: {
            admin: true,
            auth: true,
        },
    },
    {
        path: '/admin/data-collection/',
        component: () => import('../pages/data-collection/DataCollection.vue'),
        name: 'admin/data-collection',
        meta: {
            admin: true,
            auth: true,
        },
    },
    {
        path: '/admin/data-collection/market-research',
        component: () => import('../pages/data-collection/MarketResearch.vue'),
        name: 'admin/data-collection/market-research',
        meta: {
            admin: true,
            auth: true,
        },
    },
    {
        path: '/admin/data-collection/talentsonar-research',
        component: () => import('../pages/data-collection/TalentsonarResearch.vue'),
        name: 'admin/data-collection/talentsonar-research',
        meta: {
            admin: true,
            auth: true,
        },
    },
    {
        path: '/admin/data-collection/ad-traffic',
        component: () => import('../pages/data-collection/AdTraffic.vue'),
        name: 'admin/data-collection/ad-traffic',
        meta: {
            admin: true,
            auth: true,
        },
    },
    {
        path: '/admin/status/',
        component: () => import('../pages/Status.vue'),
        name: 'admin/status',
        meta: {
            admin: true,
            auth: true,
        },
    },
    {
        path: '/admin/development/',
        component: () => import('../pages/Development.vue'),
        name: 'admin/development',
        meta: {
            admin: true,
            auth: true,
        },
    },
];
