import Vue from 'vue';
import Router from 'vue-router';
import { isEmpty as _isEmpty } from 'lodash';
import routes from './routes';

Vue.use(Router);

Vue.router = new Router({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        // Skip scrolling if query string exists. Prevent scrolling during filtering data tables.
        if (! _isEmpty(to.query)) {
            return {};
        }

        if (savedPosition) {
            return savedPosition;
        }

        return { x: 0, y: 0 };
    },
});

export default Vue.router;
