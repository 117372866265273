export default [
    {
        path: '/data-management',
        component: () => import('../pages/DataManagement.vue'),
        name: 'data-management',
        meta: {
            admin: true,
            auth: true,
        },
    },
];
