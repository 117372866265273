import { format, isValid } from 'date-fns';

export default {
    filters: {
        dateFormatted(value) {
            const date = new Date(value);

            // Check if value is a valid date
            if (isValid(date)) {
                return format(date, 'dd.MM.yyyy');
            }

            return value;
        },

        datetimeFormatted(value) {
            const date = new Date(value);

            // Check if value is a valid date
            if (isValid(date)) {
                return format(date, 'dd.MM.yyyy – HH:mm');
            }

            return value;
        },

        localFormatted(value) {
            if (value === undefined) {
                return value;
            }

            if (Number(value) === value || value % 1 !== 0) {
                return value.toLocaleString('de');
            }

            return value;
        },

        trendFormatted(value) {
            if (value === undefined) {
                return value;
            }

            const valueFormatted = (Number(value) === value || value % 1 !== 0)
                ? `${value.toLocaleString('de')}%`
                : value;

            return `${value > 0 ? '+' : ''}${valueFormatted}`;
        },

        boolFormatted(value) {
            if (value === true) {
                return 'Yes';
            }

            return 'No';
        },

        nullFormatted(value) {
            if (value === null) {
                return '–';
            }

            if (value === '') {
                return '–';
            }

            return value;
        },

        percentFormatted(value) {
            if (value === undefined || value === null || Number.isNaN(+value)) {
                return value;
            }

            return `${parseFloat(value).toLocaleString('de')}%`;
        },

        listFormatted(value) {
            if (! Array.isArray(value)) {
                return value;
            }

            return value.join(', ');
        },

        percentSignFormatted(value) {
            if (value === undefined) {
                return value;
            }

            return (value >= 0 ? '+' : '-') + value;
        },

        currencyFormatted(value) {
            if (value === null || value === '–') {
                return value;
            }

            return new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
            })
                .format(value);
        },

        noDecimal(value) {
            if (typeof value === 'string') {
                return value;
            }

            if (Number(value) === value || value % 1 !== 0) {
                return parseInt(value.toFixed(0), 10);
            }

            return value;
        },

        capitalize(value) {
            return value.charAt(0)
                .toUpperCase() + value.slice(1);
        },

        rounded(value, decimals) {
            if (value === null) {
                return value;
            }

            if (value === undefined) {
                return value;
            }

            if (Number(value) !== value) {
                return value;
            }

            return Number((value).toFixed(decimals));
        },
    },

    methods: {
        applyFilter(value, filterString) {
            if (filterString === undefined) {
                return value;
            }

            const filters = filterString.split('|');
            let result = value;

            filters.forEach((filter) => {
                result = this.$options.filters[filter](result);
            });

            return result;
        },
    },
};
