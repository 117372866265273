export default function defaults() {
    return {
        horizontalGraphSeries: [],
        horizontalGraphLoading: false,

        performanceGraphSeries: [],
        performanceGraphLoading: false,

        selectedCheck: null,
        checksDetails: [],
        checksDetailsPagination: { itemsPerPage: 15 },
        checksDetailsMeta: { total: null, headers: [] },
        checksDetailsDraw: 0,
        checksDetailsLoading: false,

        verticalGraphSeries: [],
        verticalGraphLoading: false,
    };
}
