/* eslint no-param-reassign: 0 */

import defaults from './defaults';

export const horizontalGraphSeries = (state, payload) => {
    state.horizontalGraphSeries = payload;
};

export const horizontalGraphLoading = (state, payload) => {
    state.horizontalGraphLoading = payload;
};

export const performanceGraphSeries = (state, payload) => {
    state.performanceGraphSeries = payload;
};

export const performanceGraphLoading = (state, payload) => {
    state.performanceGraphLoading = payload;
};

export const selectedCheck = (state, payload) => {
    state.selectedCheck = payload;
};

export const checksDetails = (state, payload) => {
    state.checksDetails = payload;
};

export const checksDetailsLoading = (state, payload) => {
    state.checksDetailsLoading = payload;
};

export const checksDetailsPagination = (state, payload) => {
    state.checksDetailsPagination = payload;
};

export const checksDetailsMeta = (state, payload) => {
    state.checksDetailsMeta = payload;
};

export const checksDetailsDraw = (state) => {
    state.checksDetailsDraw += 1;
};

export const resetChecksDetailsPagination = (state) => {
    const defaultsObject = defaults();
    state.checksDetailsPagination = defaultsObject.checksDetailsPagination;
};

export const verticalGraphSeries = (state, payload) => {
    state.verticalGraphSeries = payload;
};

export const verticalGraphLoading = (state, payload) => {
    state.verticalGraphLoading = payload;
};

export const resetState = (state) => {
    Object.assign(state, defaults());
};
