/* eslint no-param-reassign: 0 */

import defaults from '../../vuex/defaults';

export const status = (state, payload) => {
    state.status = payload;
};

export const statusLoading = (state, payload) => {
    state.statusLoading = payload;
};

export const statusInterval = (state, payload) => {
    state.statusInterval = payload;
};

export const resetState = (state) => {
    Object.assign(state, defaults());
};
