export default [
    {
        path: '/plenty-integration',
        name: 'landing-page-plenty',
        component: () => import('../pages/LandingPage.vue'),
        meta: {
            auth: false,
            title: 'Plenty Integration',
        },
    },
];
