export default function defaults() {
    return {
        project: {},
        projectDraw: 0,
        projectLoading: true,
        keywordsLoading: false,
        keywords: [],

        rankingDataRunningChecks: false,
        rankingDataGraphSeries: [],
        rankingDataPerformance: [],
        rankingDataKeywordsPositioning: {
            data: [],
            meta: {
                headers: [],
            },
        },
        rankingDataKeywordsPositioningLoading: false,

        competitorDataDraw: 0,

        projects: [],
        projectsLoading: false,
        projectsMeta: {},
        projectsAllIds: [],
        projectsSelected: [],
        projectsDraw: 0,

        seaSections: [],
        seaSectionsLoading: false,

        contentScoreListRenderKey: 0,
    };
}
