import Vue from 'vue';
import { get as _get } from 'lodash';
import * as Sentry from '@sentry/vue';
import router from '../../router/router';
import runCrisp from '../../helpers/crisp';

const navigationMainSchema = [
    {
        icon: 'pie_chart',
        route: 'obd-general',
        children: [],
    },
    {
        icon: 'schema',
        route: 'market-analysis/analysis',
        children: [],
    },
    {
        title: 'Passports',
        icon: 'topic',
        route: null,
        children: [
            'market-analysis/general-view',
            'market-analysis/content-passports',
            'market-analysis/monitoring-passports',
            'market-analysis/social-media-passports',
            'market-analysis/sea-passports',
            'market-analysis/correlation-passports',
        ],
    },
    {
        icon: 'query_stats',
        route: 'obd-visibility-index',
        children: [],
    },
    {
        title: 'Help Center',
        icon: 'help_outline',
        href: 'https://support.praidict.de/help',
        children: [],
    },
];

const navigationContentScoreSchema = [
    {
        icon: 'sticky_note_2',
        route: 'content-scoring',
    },
    {
        icon: 'topic',
        route: 'content-passports-stand-alone',
    },
    {
        title: 'Academy',
        icon: 'school',
        href: 'https://support.praidict.de/help/content-marketing',
    },
];

function loadCrisp(response) {
    if (response.data.status !== 'success') {
        return;
    }

    if (_get(router, 'currentRoute.meta.preventCrisp') === true) {
        return;
    }

    runCrisp(response.data.user, response.data.tenant);
}

function userHasAccessToRoute(user, route, getters) {
    const routePermissions = _get(route, 'meta.permissions', []);

    // All users have access to the dashboard
    if (route.name === 'obd-general') {
        return true;
    }

    // User has access to the company feature
    if (route.meta.feature && _get(getters.tenant.features, `feature:${route.meta.feature}`) === 'disabled') {
        return false;
    }

    // User is admin
    if (user.is_admin) {
        return true;
    }

    // User has no role and access to all routes
    if (user.permissions.length === 0 && user.roles.length === 0) {
        return true;
    }

    // Match routes with roles and the roles of the user
    const permissionMatch = user.permissions.some((permission) => routePermissions.includes(permission));
    if (user.permissions.length > 0 && permissionMatch) {
        return true;
    }

    // User has access to routes without permission
    const routeWithoutPermission = user.permissions.includes('navigation show routes without permission');
    if (user.permissions.length > 0 && routePermissions.length === 0 && routeWithoutPermission) {
        return true;
    }

    return false;
}

/* eslint camelcase: 0 */
function getMainNavigation(user, getters) {
    const { routes } = router.options;

    const allowedRoutes = routes.filter((route) => userHasAccessToRoute(user, route, getters));

    const items = [];

    navigationMainSchema.forEach((navigationItem) => {
        const route = allowedRoutes.find((routeItem) => _get(routeItem, 'name') === navigationItem.route);

        const item = {
            title: _get(route, 'meta.title', null) || navigationItem.title,
            route: _get(route, 'name', null),
            icon: _get(navigationItem, 'icon', null),
            children: null,
        };

        if (navigationItem.children.length > 0) {
            item.children = navigationItem.children
                .filter((child) => allowedRoutes.find((routeItem) => _get(routeItem, 'name') === child))
                .map((child) => {
                    const childRoute = allowedRoutes.find((routeItem) => _get(routeItem, 'name') === child);

                    return {
                        title: childRoute.meta.title,
                        route: childRoute.name,
                    };
                });
        }

        // Skip parent item with no children
        if (Array.isArray(item.children) && item.children.length === 0) {
            return;
        }

        if (navigationItem.href) {
            item.href = navigationItem.href;
            items.push(item);
            return;
        }

        if (! route && navigationItem.route !== null) {
            return;
        }

        items.push(item);
    });

    return items;
}

function getContentScoreNavigation() {
    const { routes } = router.options;
    const items = [];

    navigationContentScoreSchema.forEach((navigationItem) => {
        const route = routes.find((routeItem) => _get(routeItem, 'name') === navigationItem.route);

        const item = {
            title: _get(route, 'meta.title', null) || navigationItem.title,
            route: _get(route, 'name', null),
            icon: _get(navigationItem, 'icon', null),
        };

        if (navigationItem.href) {
            item.href = navigationItem.href;
            items.push(item);
            return;
        }

        items.push(item);
    });

    return items;
}

export const loadUser = ({ commit, getters }) => Vue.axios.get('/me')
    .then((response) => {
        commit('auth', true);
        commit('app', response.data.app);

        if (response.data.user) {
            commit('user', response.data.user);
        }

        if (response.data.tenant) {
            commit('tenant', response.data.tenant);
        }

        try {
            Sentry.setTag('tenant', response.data.tenant.company.id);
        } catch (e) {} // eslint-disable-line no-empty

        commit('meStatus', response.data.status);
        commit('meLoaded', true);

        loadCrisp(response);

        if (response.data.status === 'success' && response.data.app.variant === 'enterprise') {
            commit('navigation', getMainNavigation(response.data.user, getters));
            return;
        }

        if (response.data.status === 'success' && response.data.app.variant === 'public-analysis') {
            return;
        }

        if (response.data.status === 'success'
            && response.data.app.variant === 'content-score'
            && (response.data.user.is_subscribed || response.data.user.on_trial)
        ) {
            commit('navigation', getContentScoreNavigation());
            return;
        }

        router.push({ name: 'dashboard' });
    })
    .catch(({ response }) => {
        commit('meLoaded', true);
        commit('meStatus', _get(response, 'statusText', 'failed'));
    });

export const loadTenantSelection = ({ commit }) => {
    Vue.axios.get('tenants').then(({ data }) => {
        commit('selection', data.data);
    });
};
