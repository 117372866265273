export const auth = (state) => state.auth;

export const meLoaded = (state) => state.meLoaded;

export const meStatus = (state) => state.meStatus;

export const name = (state) => state.name;

export const variant = (state) => state.variant;

export const user = (state) => state.user;

export const tenant = (state) => state.tenant;

export const selection = (state) => state.selection;

export const flash = (state) => state.flash;

export const navigation = (state) => state.navigation;

export const typeColors = (state) => state.typeColors;

export const months = (state) => state.months;
