/* eslint no-param-reassign: 0 */

import defaults from './defaults';

export const auth = (state, payload) => {
    state.auth = payload;
};

export const meLoaded = (state, payload) => {
    state.meLoaded = payload;
};

export const meStatus = (state, payload) => {
    state.meStatus = payload;
};

export const app = (state, { name, variant }) => {
    state.name = name;
    state.variant = variant;
};

export const user = (state, payload) => {
    state.user = payload;
};

export const tenant = (state, payload) => {
    state.tenant = payload;
};

export const selection = (state, payload) => {
    state.selection = payload;
};

export const flash = (state, payload) => {
    state.flash = payload;
};

export const clearFlash = (state) => {
    state.flash = null;
};

export const navigation = (state, payload) => {
    state.navigation = payload;
};

export const resetState = (state) => {
    Object.assign(state, defaults());
};
