import { get as _get } from 'lodash';
import store from '../vuex';

export default async function beforeEach(to, from, next) {
    // routes with auth needs an authenticated user
    if (! store.getters['app/meLoaded'] && (to.meta.auth || to.path === '/')) {
        await store.dispatch('app/loadUser');
    }

    // we have no dedicate home page. Redirect user to login page
    if (! store.getters['app/auth'] && to.path === '/') {
        next({ name: 'login' });
        return;
    }

    // User is not logged in and role needs auth
    if (! store.getters['app/auth'] && to.meta.auth) {
        next({ name: 'login' });
        return;
    }

    // Dashboard contains redirects. We ignore it in this function.
    // Error pages will be also ignored.
    if (to.name === 'dashboard' || to.name === '403' || to.name === '404') {
        next();
        return;
    }

    if (to.meta.alwaysAllowed) {
        next();
        return;
    }

    // All users have access to the obd-general dashboard
    if (to.name === 'obd-general' && store.getters['app/variant'] === 'enterprise') {
        next();
        return;
    }

    // Redirect root access to dashboard if user is logged in
    if (to.path === '/' && store.getters['app/auth']) {
        next({ name: 'dashboard' });
        return;
    }

    // Route has a feature. Feature must be enabled for the company
    const { features } = store.getters['app/tenant'];
    const feature = _get(features, `feature:${to.meta.feature}`);
    if (feature === 'disabled') {
        next({ name: '403' });
        return;
    }

    // Handle user roles
    const user = store.getters['app/user'];

    // User has admin permissions
    if (user.is_admin) {
        next();
        return;
    }

    // User has no role and no permissions
    if (user.permissions.length === 0 && user.roles.length === 0) {
        next();
        return;
    }

    // User has access to all routes without a permission
    const routeWithoutPermission = user.permissions.includes('navigation show routes without permission');
    if (user.permissions.length > 0 && to.meta.permissions === undefined && routeWithoutPermission) {
        next();
        return;
    }

    const permissionMatch = user.permissions.some((permission) => to.meta.permissions.includes(permission));
    if (user.permissions.length > 0 && permissionMatch) {
        next();
        return;
    }

    // Default
    next({ name: '403' });
}
