/* eslint arrow-body-style: 0 */

import Vue from 'vue';

export const loadProject = ({ commit }, projectId) => {
    commit('projectLoading', true);

    Vue.axios.get(`projects/${projectId}`)
        .then(({ data }) => {
            commit('project', data.data);
        })
        .finally(() => {
            commit('projectLoading', false);
        });
};

export const loadKeywords = ({ commit }, projectId) => {
    commit('keywordsLoading');

    return Vue.axios.get(`/projects/${projectId}/keywords/focused`)
        .then((response) => {
            commit('keywords', response.data);
            commit('keywordsLoading');
        });
};

export const loadRankingDataGraphSeries = ({ commit }, projectId) => {
    return Vue.axios.get(`/projects/ranking-data/graph/${projectId}`)
        .then(({ data }) => {
            const series = [{
                name: 'Average Rankingpostion',
                data: data.data,
            }];

            commit('rankingDataGraphSeries', series);
            commit('rankingDataRunningChecks', data.meta.hasRunningChecks);
        });
};

export const loadRankingDataPerformance = ({ commit }, projectId) => {
    return Vue.axios.get(`/projects/ranking-data/performance/${projectId}`)
        .then((response) => {
            commit('rankingDataPerformance', response.data);
        });
};

export const loadRankingDataKeywordsPositioning = ({ commit }, { projectId, range = null }) => {
    commit('rankingDataKeywordsPositioningLoading');

    let params = {};
    if (range) {
        params = range;
    }

    return Vue.axios.get(`/projects/ranking-data/keywords/${projectId}`, { params })
        .then(({ data }) => {
            commit('rankingDataKeywordsPositioning', data);
            commit('rankingDataKeywordsPositioningLoading');
        })
        .catch(() => {
            commit('rankingDataKeywordsPositioningLoading');
        });
};

export const loadSeaSections = ({ commit }, projectId) => {
    commit('seaSectionsLoading');

    return Vue.axios.get(`/project/sea/${projectId}`)
        .then(({ data }) => {
            commit('seaSections', data);
            commit('seaSectionsLoading');
        })
        .catch(() => {
            commit('seaSectionsLoading');
        });
};

export const changeSeaSectionActive = ({ commit }, { sectionId, active }) => {
    return Vue.axios.post(`/project/sea/active/${sectionId}`, {
        active,
    })
        .then(() => {
            commit('changeSeaSectionActive', {
                sectionId,
                active,
            });
        });
};

export const resetSeaSections = ({ commit }) => {
    commit('seaSections', []);
};

export const loadProjects = ({ commit, state }, { query, tool }) => {
    commit('projectsLoading', true);
    commit('projectsDraw');

    Vue.axios.get('/projects', {
        params: {
            draw: state.projectsDraw,
            ...query,
            tool,
        },
    })
        .then(({ data }) => {
            if (state.projectsDraw !== data.meta.draw) {
                return;
            }

            commit('projects', data.data);
            commit('projectsMeta', {
                total: data.meta.total,
            });
            commit('projectsAllIds', data.meta.allProjects);
            commit('projectsLoading', false);

            if (data.meta.draw === 1) {
                commit('projectsSelectAll');
            }
        })
        .catch(() => {
            commit('projectsLoading', false);
        });
};
