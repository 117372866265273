export const keywordIds = (state) => state.keywordIds;
export const mindsetCount = (state) => state.mindsetCount;

export const searchVolume = (state) => state.searchVolume;
export const searchVolumeLoading = (state) => state.searchVolumeLoading;

export const tree = (state) => state.tree;
export const treeMeta = (state) => state.treeMeta;
export const treeLoading = (state) => state.treeLoading;
export const treeSelection = (state) => state.treeSelection;
export const activePanels = (state) => state.activePanels;

export const filterVisibility = (state) => state.filterVisibility;
export const filterLayer = (state) => state.filterLayer;
export const filter = (state) => state.filter;
export const filterErrors = (state) => state.filterErrors;

export const competitorDraw = (state) => state.competitorsDraw;

export const options = (state) => state.options;
