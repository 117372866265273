import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import router from './router';

// eslint-disable-next-line func-names
export default function () {
    if (! window.config.dsn) {
        return;
    }

    const ignoreErrors = [
        'Request failed with status code 500',
        'Request failed with status code 503',
        'Request failed with status code 504',
        'Request failed with status code 401',
        'Request failed with status code 403',
        'Request failed with status code 404',
        'Request failed with status code 405',
        'The token has been blacklisted',
        'Network Error',
    ];

    let { host } = window.config;
    host = host.replaceAll('.', '\\.');

    Sentry.init({
        Vue,
        dsn: window.config.dsn,
        environment: window.config.env,
        ignoreErrors,
        allowUrls: [
            new RegExp(`https?:\/\/((cdn|www)\.)?${host}`), // eslint-disable-line
            'webpack-internal://',
        ],
        integrations: [new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        })],
        tracePropagationTargets: [
            new RegExp(`^https:\/\/${host}\/api`), // eslint-disable-line
        ],
    });
}
